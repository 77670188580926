import React, { createRef, useState } from 'react';
import { Link, useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { useLocation } from '@reach/router';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import slugify from 'slugify';
import Collapse from 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';

/** Images */
import Logo from '@images/logo-big-light.png';
import FrenchFlag from '@images/flag-fr.png';
import EnglishFlag from '@images/flag-en.png';

/** Root */
import { Language } from '@root/models/language.enum';
import { FaqUrlResolver } from '@root/faq-url-resolver';
import { getPathByLanguage } from '@root/util';

const registerPath = '/register';
const dcaSimulatorPath = '/dca-simulator';

const getFlagByLanguage = (language: string): JSX.Element => {
  if (language === Language.French) {
    return <img src={FrenchFlag} />;
  }

  return <img src={EnglishFlag} />;
};

const isCurrentPage = (
  linkPath: string,
  pathname: string,
  currentLang: string,
  clickedAnchorPath: string,
): boolean => {
  if (clickedAnchorPath.length) {
    if (
      clickedAnchorPath === linkPath ||
      clickedAnchorPath === `/${currentLang}${linkPath}`
    ) {
      return true;
    }

    return false;
  }

  if (pathname === linkPath || pathname === `/${currentLang}${linkPath}`) {
    return true;
  }

  return false;
};

const Navbar = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const { changeLanguage } = useI18next();
  const { pathname } = useLocation();
  const [clickedAnchorPath, setClickedAnchorPath] = useState('');
  const currentLang = i18n.language;
  const collapseRef = createRef<HTMLDivElement>();
  const onClickCollapse = () => {
    if (!collapseRef.current) {
      return;
    }

    const collapseInstance = Collapse.getInstance(collapseRef.current);

    if (collapseInstance && collapseRef.current.classList.contains('show')) {
      collapseInstance.hide();
    }
  };
  const linkList = [
    {
      text: 'navbar.home',
      path: '/',
      isAnchor: false,
      isAbsolute: false,
    },
    {
      text: 'home.bots.automatedInvest',
      path: getPathByLanguage(
        currentLang,
        `/#${slugify(t('home.bots.automatedInvestment').toLowerCase())}`,
      ),
      isAnchor: true,
      isAbsolute: false,
    },
    {
      text: 'home.dca.recurringInvest',
      path: getPathByLanguage(
        currentLang,
        `/#${slugify(t('home.dca.recurringInvestment').toLowerCase())}`,
      ),
      isAnchor: true,
      isAbsolute: false,
    },
    {
      text: 'navbar.testimonials',
      path: getPathByLanguage(
        currentLang,
        `/#${slugify(t('navbar.testimonials').toLowerCase())}`,
      ),
      isAnchor: true,
      isAbsolute: false,
    },
    {
      text: 'navbar.dcaSimulator',
      path: dcaSimulatorPath,
      isAnchor: false,
      isAbsolute: false,
    },
    {
      text: 'navbar.faq',
      path: FaqUrlResolver.get('base', currentLang),
      isAnchor: false,
      isAbsolute: true,
    },
    {
      text: 'navbar.logIn',
      path: process.env.GATSBY_APP_URL,
      isAnchor: false,
      isAbsolute: true,
    },
    {
      text: 'navbar.register',
      path: registerPath,
      isAnchor: false,
      isAbsolute: false,
    },
  ];

  return (
    <nav className="navbar navbar-expand-xxl navbar-light pt-0 pb-0 fixed-top shadow d-block">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src={Logo} className="logo-size ms-2" alt="Whalinvest logo" />
        </Link>
        <button
          className="navbar-toggler me-1"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarMenu"
          aria-controls="navbarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          ref={collapseRef}
          className="collapse navbar-collapse"
          id="navbarMenu"
        >
          <div className="w-100 d-md-block d-lg-flex justify-content-end">
            <ul className="navbar-nav text-center">
              {linkList.map(link => {
                const currentPage = isCurrentPage(
                  link.path as string,
                  pathname,
                  currentLang,
                  clickedAnchorPath,
                );
                const className = currentPage ? 'nav-item active' : 'nav-item';

                let classNameLink = 'nav-link fw-bold';

                if (link.path === registerPath) {
                  classNameLink = `btn btn-primary text-body`;
                }

                if (link.isAnchor) {
                  return (
                    <li className={className} key={link.path}>
                      <AnchorLink
                        className={classNameLink}
                        to={link.path as string}
                        onAnchorLinkClick={() => {
                          setClickedAnchorPath(link.path as string);
                          onClickCollapse();
                        }}
                      >
                        {t(link.text)}
                      </AnchorLink>
                    </li>
                  );
                }

                if (link.isAbsolute) {
                  return (
                    <li className={className} key={link.path}>
                      <a
                        className={classNameLink}
                        href={link.path}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t(link.text)}
                      </a>
                    </li>
                  );
                }

                return (
                  <li className={className} key={link.path}>
                    <Link
                      className={classNameLink}
                      to={link.path as string}
                      onClick={() => setClickedAnchorPath('')}
                    >
                      {t(link.text)}
                    </Link>
                  </li>
                );
              })}
              <li className="nav-item dropdown">
                <a
                  href="#"
                  className="btn nav-link dropdown-toggle"
                  id="navbarDropdownLanguage"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  role="button"
                >
                  {getFlagByLanguage(currentLang)}
                </a>
                <ul
                  className="dropdown-menu dropdown-menu-end end-0 m-0"
                  aria-labelledby="navbarDropdownLanguage"
                >
                  <li>
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => changeLanguage(Language.English)}
                    >
                      {getFlagByLanguage(Language.English)}
                      <span className="ms-1 align-middle">
                        {t('navbar.language.en')}
                      </span>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => changeLanguage(Language.French)}
                    >
                      {getFlagByLanguage(Language.French)}
                      <span className="ms-1 align-middle">
                        {t('navbar.language.fr')}
                      </span>
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
