import React, { useState, createRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import {
  FaDiscord,
  FaTwitter,
  FaLinkedin,
  FaFacebook,
  FaYoutube,
  FaGithub,
  FaEnvelope,
} from 'react-icons/fa';
import 'bootstrap/js/dist/modal';
import HCaptcha from '@hcaptcha/react-hcaptcha';

/** Root */
import { FaqUrlResolver } from '@root/faq-url-resolver';

const Footer = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const [captchaCode, setCaptchaCode] = useState('');
  const refCaptcha = createRef<HCaptcha>();

  const reset = () => {
    setCaptchaCode('');

    if (refCaptcha.current) {
      refCaptcha.current.resetCaptcha();
    }
  };

  return (
    <footer className="bg-dark-grey mt-auto py-3">
      <div id="emailModal" className="modal fade" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                <FaEnvelope />
                <span className="align-middle ms-2">{t('footer.email')}</span>
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body d-flex justify-content-center">
              {!captchaCode.length ? (
                <HCaptcha
                  ref={refCaptcha}
                  sitekey={process.env.GATSBY_HCAPTCHA_SITEKEY as string}
                  onVerify={(token: string) => setCaptchaCode(token)}
                />
              ) : (
                <p className="text-center fs-3 mb-0">
                  <a
                    href="mailto:contact@iofate.com?subject=Contact%20Whalinvest"
                    className="text-decoration-none"
                  >
                    contact@iofate.com
                  </a>
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  reset();
                }}
              >
                {t('footer.close')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row pt-5 pb-5">
          <div className="col-sm-4">
            <StaticImage
              src="../images/logo-no-baseline.png"
              alt="logo"
              className="img-fluid"
            />
            <p className="text-white mt-3 mb-0">{t('footer.description')}</p>
          </div>
          <div className="col-sm-4 mt-4 mt-sm-0">
            <section className="text-center">
              <h6 className="text-primary mb-1">{t('footer.beginWith')}</h6>
              <p className="text-white">{t('footer.launchInvest')}</p>
              <Link
                className="btn btn-primary text-body ps-5 pe-5"
                to="/register"
              >
                {t('navbar.register').toUpperCase()}
              </Link>
            </section>
            <section className="mt-3 mt-sm-5 ps-0 ps-sm-5">
              <h6 className="text-primary mb-3">{t('footer.findUs')}</h6>
              <ul className="ps-0">
                <li>
                  <button
                    type="button"
                    className="btn btn-link footer-link text-decoration-none p-0"
                    data-bs-toggle="modal"
                    data-bs-target="#emailModal"
                  >
                    <FaEnvelope /> Email
                  </button>
                </li>
                <li>
                  <a
                    className="footer-link text-decoration-none"
                    href={process.env.GATSBY_DISCORD_INVITE}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaDiscord /> Discord
                  </a>
                </li>
                <li>
                  <a
                    className="footer-link text-decoration-none"
                    href="https://twitter.com/getwhalinvest"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaTwitter /> Twitter
                  </a>
                </li>
                <li>
                  <a
                    className="footer-link text-decoration-none"
                    href="https://www.facebook.com/Whalinvest"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaFacebook /> Facebook
                  </a>
                </li>
                <li>
                  <a
                    className="footer-link text-decoration-none"
                    href="https://www.linkedin.com/company/iofate"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaLinkedin /> Linkedin
                  </a>
                </li>
                <li>
                  <a
                    className="footer-link text-decoration-none"
                    href="https://www.youtube.com/whalinvest"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaYoutube /> Youtube
                  </a>
                </li>
                <li>
                  <a
                    className="footer-link text-decoration-none"
                    href="https://github.com/IOfate"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaGithub /> Github
                  </a>
                </li>
              </ul>
            </section>
          </div>
          <div className="col-sm-4">
            <section>
              <h6 className="text-primary mb-3">Whalinvest.com</h6>
              <Link className="footer-link text-decoration-none d-block" to="/">
                {t('footer.homePage')}
              </Link>
              <a
                className="footer-link text-decoration-none d-block"
                href={process.env.GATSBY_APP_URL}
                target="_blank"
                rel="noreferrer"
              >
                {t('navbar.logIn')}
              </a>
              <a
                className="footer-link text-decoration-none d-block"
                href={FaqUrlResolver.get('begin', currentLang)}
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.beginOnWh')}
              </a>
              <a
                className="footer-link text-decoration-none d-block"
                href={FaqUrlResolver.get('base', currentLang)}
                target="_blank"
                rel="noreferrer"
              >
                FAQ
              </a>
              <a
                className="footer-link text-decoration-none d-block"
                href={FaqUrlResolver.get('invoicing', currentLang)}
                target="_blank"
                rel="noreferrer"
              >
                {t('navbar.pricing')}
              </a>
              <a
                className="footer-link text-decoration-none d-block ms-3"
                href={FaqUrlResolver.get('payment', currentLang)}
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.payment')}
              </a>
              <a
                className="footer-link text-decoration-none d-block ms-3"
                href={FaqUrlResolver.get('referral', currentLang)}
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.referral')}
              </a>
              <a
                className="footer-link text-decoration-none d-block"
                href={process.env.GATSBY_GOODIES_URL}
                target="_blank"
                rel="noreferrer"
              >
                Goodies
              </a>
              <a
                href="https://www.whalinvest.com/cgv-fr.pdf"
                target="_blank"
                rel="noreferrer"
                className="footer-link text-decoration-none d-block"
              >
                {t('footer.termsOfSales')}
              </a>
              <Link
                className="footer-link text-decoration-none d-block"
                to="/legal-terms"
              >
                {t('footer.legalTerms')}
              </Link>
            </section>
          </div>
        </div>
        <div className="row mt-2 pb-3">
          <div className="col-sm-12">
            <p className="mb-0 text-center fw-bold text-muted">
              Whalinvest - © {new Date().getFullYear()} - IOfate SAS
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
