import React from 'react';

const ArrowUp = (): JSX.Element => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M26.711 20.273l-9.999-9.977a1.012 1.012 0 00-1.429 0l-9.991 9.97c-.66.634-.162 1.748.734 1.723h19.943c.893.023 1.377-1.083.742-1.716zm-10.713-7.84l7.566 7.55H8.432l7.566-7.55z"
        fill="#FFFFFF"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default ArrowUp;
