import React, { Fragment, PropsWithChildren } from 'react';
import CookieConsent from 'react-cookie-consent';
import { FaTimes, FaCheck } from 'react-icons/fa';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { useTranslation } from 'gatsby-plugin-react-i18next';

/** Components */
import Footer from './footer';
import Navbar from './navbar';
import ScrollTopButton from './scroll-top.button';

type Props = {
  className?: string;
  classNameContainer?: string;
} & PropsWithChildren<any>;

const MainContainer = (props: Props): JSX.Element => {
  const { children, className, classNameContainer } = props;
  const { t } = useTranslation();
  const location = useLocation();

  let appliedClassName: string[] = [
    'd-flex',
    'flex-column',
    'h-100',
    'min-vh-100',
  ];

  let appliedClassNameContainer = ['container-fluid', 'pe-0', 'ps-0'];

  if (className) {
    appliedClassName = className.split(' ');
  }

  if (classNameContainer) {
    appliedClassNameContainer = appliedClassNameContainer.concat(
      classNameContainer.split(' '),
    );
  }

  return (
    <main className={appliedClassName.join(' ')}>
      <Navbar />
      <section className={appliedClassNameContainer.join(' ')}>
        {children}
      </section>
      <ScrollTopButton />
      <Footer />
      <CookieConsent
        overlay
        enableDeclineButton
        disableButtonStyles
        overlayClasses="overlay-gdpr"
        location="bottom"
        cookieName="gdpr-google-analytics"
        containerClasses="bg-primary"
        buttonWrapperClasses="animate__animated animate__pulse animate__delay-2s"
        declineButtonClasses="btn btn-sm btn-danger ms-2 ms-sm-0 mb-3 mb-sm-0"
        buttonClasses="btn btn-sm btn-success ms-2 me-2 mb-3 mb-sm-0"
        onAccept={() => initializeAndTrack(location)}
        declineButtonText={
          <Fragment>
            <FaTimes />
            <span className="align-middle ms-2">{t('global.decline')}</span>
          </Fragment>
        }
        buttonText={
          <Fragment>
            <FaCheck />
            <span className="align-middle ms-2">{t('global.authorized')}</span>
          </Fragment>
        }
      >
        {t('global.gdprConsent')}
      </CookieConsent>
    </main>
  );
};

export default MainContainer;
