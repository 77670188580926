import { Language } from './models/language.enum';

type FaqPath = 'base' | 'begin' | 'bots' | 'payment' | 'invoicing' | 'referral';

const mapPathByLocal: any = {
  [Language.French]: {
    base: '',
    begin: 'debuter-sur-whalinvest',
    bots: 'creer-des-robots-de-trading',
    payment: 'paiement',
    invoicing: 'facturation',
    referral: 'parrainage',
  },
  [Language.English]: {
    base: '',
    begin: 'getting-started',
    bots: 'how-to-create-trading-bots',
    payment: 'payment',
    invoicing: 'billing',
    referral: 'referral',
  },
};

export class FaqUrlResolver {
  private static readonly baseUrl = process.env.GATSBY_DOCS_URL;

  static get(path: FaqPath, local: string): string {
    let url = FaqUrlResolver.baseUrl;

    if (local === Language.English) {
      url = `${url}v/english/`;
    }

    return `${url}${mapPathByLocal[local][path]}`;
  }
}
