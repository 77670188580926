import { Language } from '@root/models/language.enum';

export const asyncWrap = async <T = any, Y = Error>(
  promise: Promise<T>,
): Promise<{ error: Y | null; result: T | null }> => {
  return promise
    .then((result: T) => ({ result, error: null }))
    .catch((error: Y) => ({ error, result: null }));
};

export const getPathByLanguage = (language: string, path: string): string => {
  if (language === Language.English) {
    return path;
  }

  return `/${language}${path}`;
};

export const getWindow = (): Window => {
  if (typeof window !== 'undefined') {
    return window;
  }

  return {} as any;
};

export const waitForWindow = (): Promise<Window> =>
  new Promise<Window>(resolve => {
    let windowRef = window;

    while (!windowRef) {
      windowRef = window;
    }

    resolve(windowRef);
  });

export const orderAlphabetically = (list: string[]): string[] =>
  list.sort((a: string, b: string) => (a < b ? -1 : 1));
