import React, { useEffect, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

/** Root */
import { getWindow } from '@root/util';
import { Language } from '@root/models/language.enum';

/** Components */
import ArrowUp from './svg/arrow-up';

const triggerDetection = 20;

const ScrollTopButton = (): JSX.Element => {
  const { i18n } = useTranslation();
  const [visible, setVisible] = useState(false);
  const window = getWindow();
  const currentLang = i18n.language;
  const path =
    currentLang !== Language.English ? `/${currentLang}/#head` : '/#head';
  const scrollSpyHandler = () => {
    if (
      document.body.scrollTop > triggerDetection ||
      document.documentElement.scrollTop > triggerDetection
    ) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollSpyHandler);

    return () => window.removeEventListener('scroll', scrollSpyHandler);
  }, []);

  return (
    <AnchorLink
      stripHash
      className="p-2 btn-primary rounded-circle position-fixed bottom-0 end-0 me-2 mb-2"
      to={path}
      gatsbyLinkProps={{
        style: {
          display: visible ? 'block' : 'none',
        },
      }}
    >
      <ArrowUp />
    </AnchorLink>
  );
};

export default ScrollTopButton;
